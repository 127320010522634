/* .rbc-time-slot {
  min-height: 20px;
} */
/* .rbc-time-header {
  height: 0px;
} */

.rbc-label {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
}
